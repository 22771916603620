<template>
  <PflegeBaseSectionTemplate
    v-editable="props.blok"
    :backgroundColor="getPflegeColornameFromHex(props.blok.backgroundColor.value)"
    :data-uid="props.blok._uid"
    :anchor="anchor"
    :textAlign="props.blok.textAlign"
  >
    <template v-if="props.blok.media?.length > 0" #media>
      <WPElementMedia
        v-for="asset in props.blok.media"
        :key="asset._uid"
        :blok="asset"
        :aspectRatio="{
          desktop: aspectRatios.xs,
          tablet: aspectRatios.s,
          mobile: aspectRatios.s
        }"
      />
    </template>

    <template v-if="props.blok.intro?.length > 0" #intro>
      <IntroComponent :blok="props.blok.intro[0]" />
    </template>

    <template v-if="props.blok.firstDivider?.length > 0" #firstDivider>
      <Divider :blok="props.blok.firstDivider" />
    </template>

    <template v-if="props.blok.content?.length > 0" #content>
      <PflegeScrollSnapSlider :slider="props.blok.content">
        <template #default="{ slide }">
          <CardComponent :blok="slide" />
        </template>
      </PflegeScrollSnapSlider>
    </template>

    <template v-if="props.blok.secondDivider?.length > 0" #secondDivider>
      <Divider :blok="props.blok.secondDivider" />
    </template>

    <template v-if="props.blok.closing?.length > 0" #closing>
      <ClosingComponent :blok="props.blok.closing[0]" />
    </template>

    <template v-if="props.blok.thirdDivider?.length > 0" #thirdDivider>
      <Divider :blok="props.blok.thirdDivider" />
    </template>
  </PflegeBaseSectionTemplate>
</template>

<script setup>
import { PflegeBaseSectionTemplate, PflegeScrollSnapSlider } from '@shared'

const props = defineProps({
  blok: {
    type: Object,
    required: true
  }
})
const anchor = computed(() => {
  return props.blok.intro?.length > 0 && props.blok.intro[0].anchor !== null
    ? props.blok.intro[0].anchor
    : ''
})
const { getPflegeColornameFromHex } = usePflegeUtils()
const mainComponentProvide = useMainComponentProvide()
mainComponentProvide.init()
</script>
